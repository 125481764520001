<template>
  <div style="padding: 20px 0;">
    <infoCard :infoStatus.sync="infoStatus" @infoPlatform="infoPlatform"></infoCard>
    <el-divider></el-divider>
    <el-card v-if="infoStatus.status===1&&infoStatus.is_apply===1&&userPermissions.indexOf('inquiry_apply') !== -1"
             class="box-card">
      <div slot="header" class="clearfix">
        <el-link class="title">报名</el-link>
      </div>
      <applyTable v-if="infoStatus?.status!==undefined" :infoPlatforms="infoPlatforms"
                  :refresh.sync="refresh" :infoStatus="infoStatus"></applyTable>
    </el-card>
    <br>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <el-link class="title">已报名列表</el-link>
      </div>
      <enrolledTable v-if="projectId!==''&&infoStatus?.status!==undefined" :projectId="projectId"
                     :refresh.sync="refresh" :infoPlatforms="infoPlatforms" :infoStatus="infoStatus"></enrolledTable>
    </el-card>
    <br>
    <br>
    <log v-if="projectId!==''&&userPermissions.indexOf('inquiry_apply_log') !== -1" :projectId="projectId"
         :refresh.sync="refresh"></log>

  </div>

</template>

<script>
import PriceGrids from '@/pages/publication/PriceGrids'
import infoCard from './components/infoCard'
import { mapGetters } from 'vuex'
import applyTable from '@/pages/inquiries/apply/applyTable.vue'
import enrolledTable from '@/pages/inquiries/apply/enrolledTable.vue'
import log from '@/pages/inquiries/components/log.vue'

export default {
  name: 'InfoDetail',
  components: {
    PriceGrids, infoCard, applyTable, enrolledTable, log
  },
  data() {
    return {
      infoData: {},
      multipleSelection: [],
      drawer: false,
      infoPlatforms: [],
      infoStatus: {},
      tabPlatforms: [],
      divideLog: [],
      projectId: '',
      refresh: false
    }
  },

  computed: {
    ...mapGetters(['userPermissions', 'userInfo']),
  },

  methods: {
    infoPlatform(val) {
      this.infoPlatforms = val
    }
  },
  mounted() {
    this.projectId = this.$route.params.id
  }
}
</script>

<style lang="scss" scoped>
.newForm {
  /*border: 1px solid red;*/
  .el-form-item__label {
    color: black;
    font-weight: 600;
  }

  .el-form-item--mini.el-form-item {
    margin-bottom: 13px;
  }
}

.title {
  font-size: 18px;
  font-weight: 800;
}

</style>

