<template>
  <div class="default-table">
    <el-table :data="applyList"
              border
              stripe
              style="width: 100%">
      <el-table-column
          label="序号"
          type="index"
          width="80"
      >
      </el-table-column>
      <el-table-column
          label="红人"
          prop="nickname"
      >
        <template slot-scope="{row}">
          <el-tag v-if="row.is_assign==1" type="danger">定向</el-tag>
          {{ row.nickname }}
        </template>
      </el-table-column>
      <el-table-column
          label="合作意向"
          width="300"
      >
        <template slot-scope="{row}">
          <!-- 意向 1:试用 2:可接 3:拒接-->
          {{
            row?.intention === 1 ? '试用' : row.intention === 2 ? '可接' : '拒接 '
          }}
        </template>
      </el-table-column>
      <el-table-column
          label="意向说明"
          prop="intention_note">
      </el-table-column>
      <el-table-column
          label="备注"
          prop="note">
      </el-table-column>
      <el-table-column
          label="平台"
          prop="platform_name">
      </el-table-column>
      <el-table-column
          label="状态"
          prop="status">
        <template slot-scope="{row}">
          {{ row.confirm_status === 2 ? '已合作' : row.employ_status === 2 ? '已初选' : '已报名' }}
        </template>
      </el-table-column>
      <el-table-column
          v-if="infoStatus.status===1" label="操作"
          width="120"
      >
        <template slot-scope="{row}">
          <el-popconfirm
              v-if="infoStatus.status===1&&(row.confirm_status!==2||row.employ_status!==2)"
              :title="`确定要取消【${row.nickname}】的【${row.platform_name}】报名吗？`"
              confirm-button-text='好的'
              @confirm="handleVoid(row.id)"
          >
            <el-link v-if="userPermissions.indexOf('inquiry_apply_cancel') !== -1" slot="reference"
                     icon="el-icon-circle-close"
                     size="mini"
                     type="primary">取消报名
            </el-link>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

  </div>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'enrolledTable',
  data() {
    return {
      applyList: []
    }
  },
  props: {
    infoPlatforms: {
      type: Array
    },
    infoStatus: {
      type: Object
    },
    projectId: {
      type: [Number, String]
    },
    refresh: {
      type: Boolean
    }
  },
  watch: {
    'refresh'(val) {
      if (val) {
        this.getInfo()
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo'])
  },

  methods: {
    async getInfo() {
      let data = await this.$api.applyList({
        project_id: this.projectId,
        status: this.infoStatus.status
      })
      this.applyList = data.details_all_about_me
      //InquiryKolList
      this.$emit('update:refresh', false)

    }
    ,
    async handleVoid(rowId) {
      // "status": 2 //1:进行中 2:已完结 3:已作废
      let statusData = {
        ids: [rowId],
        project_id: this.projectId
      }
      let id = await this.$api.cancelApply(statusData)
      if (id) {
        this.$notify.success('取消报名成功')
        this.$emit('update:refresh', true)

      }
    }

  }
  ,
  mounted() {
    this.getInfo()
  }
}
</script>

<style scoped>

</style>
